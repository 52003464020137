import CB_Hero from "../assets/consultation-business-pic/CB_Hero.png";
import CB_Hero_1 from "../assets/consultation-business-pic/CB_Hero_1.png";
import CB_Card_1 from "../assets/consultation-business-pic/CB_Card_1.png";
import CB_Card_2 from "../assets/consultation-business-pic/CB_Card_2.png";
import CB_Card_3 from "../assets/consultation-business-pic/CB_Card_3.png";
import CB_Card_4 from "../assets/consultation-business-pic/CB_Card_4.png";

const consultationBusinessPic = {
    CB_Hero,
    CB_Hero_1,
    CB_Card_1,
    CB_Card_2,
    CB_Card_3,
    CB_Card_4,
};

export default consultationBusinessPic;

import kumparan from "../assets/ulasan-logo/kumparan.png";
import wartaEkonomi from "../assets/ulasan-logo/warta_ekonomi.png";
import cnbc from "../assets/ulasan-logo/cnbc.png";
import startmeup from "../assets/ulasan-logo/startmeup.png";
import gaptekin from "../assets/ulasan-logo/gaptekin.png";

const UlasanLogo = {
    kumparan,
    wartaEkonomi,
    cnbc,
    startmeup,
    gaptekin,
};

export default UlasanLogo;
const kelasBisnis = [
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 4.5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
  {
    title: "Langkah Sukses Jadi Youngtrepreneur",
    star: 5,
    pendaftar: 2124,
    tipe: "Materi Eksklusif",
    level: "Pemula",
    price: 500000,
    discount: 100,
    popular: true,
  },
];

module.exports = {
  kelasBisnis,
};

import client01 from "../assets/client-pic/afkar jean.png";
import client02 from "../assets/client-pic/asinin.aja.png";
import client03 from "../assets/client-pic/barokah herbal center.png";
import client04 from "../assets/client-pic/bites by gummy world.png";
import client05 from "../assets/client-pic/dominatus team.png";
import client06 from "../assets/client-pic/heramour.png";
import client07 from "../assets/client-pic/Leiron Skincare.png";
import client08 from "../assets/client-pic/Mahabbah.png";
import client09 from "../assets/client-pic/Pecel Kawi.png";
import client10 from "../assets/client-pic/risera beauty.png";
import client11 from "../assets/client-pic/tempat belanja.png";
import client12 from "../assets/client-pic/yesamalika.png";

const clientPic = {
  client01,
  client02,
  client03,
  client04,
  client05,
  client06,
  client07,
  client08,
  client09,
  client10,
  client11,
  client12,
};

export default clientPic;

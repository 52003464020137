import pic1 from "../assets/kelas-bisnis/pic_1.png";
import heroKelasBisnis1 from "../assets/kelas-bisnis/hero_kelas_bisnis_1.png";
import heroKelasBisnisSmall1 from "../assets/kelas-bisnis/hero_kelas_bisnis_small_1.png";

const kelasBisnisPic = {
  pic1,
  heroKelasBisnis1,
  heroKelasBisnisSmall1,
};

export default kelasBisnisPic;

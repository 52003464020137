import modalku from "../assets/partner-logo/modalku.png";
import Datapinter from "../assets/partner-logo/Datapinter.png";
import Kalodata from "../assets/partner-logo/Kalodata.png";

const partnerLogo = {
  modalku,
  Datapinter,
  Kalodata
};

export default partnerLogo;

import carouselComunityPic01 from "../assets/carousel-comunity-pic/carousel-comunity-pic-01.png";
import carouselComunityPic02 from "../assets/carousel-comunity-pic/carousel-comunity-pic-02.png";
import carouselComunityPic03 from "../assets/carousel-comunity-pic/carousel-comunity-pic-03.png";
import carouselComunityPic04 from "../assets/carousel-comunity-pic/carousel-comunity-pic-04.png";
import carouselComunityPic05 from "../assets/carousel-comunity-pic/carousel-comunity-pic-05.png";
import carouselComunityPic06 from "../assets/carousel-comunity-pic/carousel-comunity-pic-06.png";
import carouselComunityPic07 from "../assets/carousel-comunity-pic/carousel-comunity-pic-07.png";
import carouselComunityPic08 from "../assets/carousel-comunity-pic/carousel-comunity-pic-08.png";
import carouselComunityPic09 from "../assets/carousel-comunity-pic/carousel-comunity-pic-09.png";
import carouselComunityPic10 from "../assets/carousel-comunity-pic/carousel-comunity-pic-10.png";
import carouselComunityPic11 from "../assets/carousel-comunity-pic/carousel-comunity-pic-11.png";
import carouselComunityPic12 from "../assets/carousel-comunity-pic/carousel-comunity-pic-12.png";

const carouselComunityPic = {
    carouselComunityPic01,
    carouselComunityPic02,
    carouselComunityPic03,
    carouselComunityPic04,
    carouselComunityPic05,
    carouselComunityPic06,
    carouselComunityPic07,
    carouselComunityPic08,
    carouselComunityPic09,
    carouselComunityPic10,
    carouselComunityPic11,
    carouselComunityPic12,
};

export default carouselComunityPic;

import rectangleNews1 from "../assets/news-pic/rectangle_news_1.png";
import rectangleNews2 from "../assets/news-pic/rectangle_news_2.png";
import rectangleNews3 from "../assets/news-pic/rectangle_news_3.png";

const newsPic = {
  rectangleNews1,
  rectangleNews2,
  rectangleNews3,
};

export default newsPic;

import React from "react";
import CheckoutFree from "../../components/chekcout-free/CheckoutFree";

function FreeCheckout () {

    return (
        <div className=" flex flex-col justify-center items-center shrink-0">
            <CheckoutFree />
        </div>
    );
}

export default FreeCheckout;
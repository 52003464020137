import heroPic from "../assets/team-pic/hero-pic.jpg";
import team1 from "../assets/team-pic/team-1.png";
import team2 from "../assets/team-pic/team-2.2.png";

const teamPic = {
    heroPic,
    team1,
    team2,
};

export default teamPic;

import rectangle74 from "../assets/komunitas-pic/rectangle_74.png";
import komunitasKontenCreator from "../assets/komunitas-pic/komunitas_konten_creator.png";
import komunitasBisnis from "../assets/komunitas-pic/komunitas_bisnis.png";

const komunitasPic = {
  rectangle74,
  komunitasBisnis,
  komunitasKontenCreator,
};

export default komunitasPic;
